
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {

        // Data function
        private data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                unityIconPathAndName:
                    require("@/assets/images/unity.png"),
            };
        }
    }
